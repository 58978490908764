<template>
  <loader v-bind="{ loading }">
    <columns>
      <column>
        <page-heading :heading="branch.name"></page-heading>
      </column>
      <column class="is-narrow">
        <tabs>
          <tab-link name="branch-manager" :params="{ branch: branch.uuid }">Overview</tab-link>
          <tab-link name="branch-work-orders" :params="{ branch: branch.uuid }">Work Orders</tab-link>
          <tab-link name="branch-staff" :params="{ branch: branch.uuid }">Staff</tab-link>
          <tab-link name="branch-teams" :params="{ branch: branch.uuid }">Teams</tab-link>
          <tab-link name="branch-preferences" :params="{ branch: branch.uuid }">Preferences</tab-link>
        </tabs>
      </column>
    </columns>
    <router-view />
  </loader>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

  data: () => ({
    loading: true
  }),

  async beforeCreate() {
    await this.$store.dispatch('branch/loadBranch', this.$route.params.branch)
    this.loading = false
  },

  beforeDestroy() {
    this.$store.commit('branch/clearBranch')
  },

  computed: mapGetters('branch', [
    'branch'
  ])

}
</script>